import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import HeroSlide from './HeroSlide';

const StyledSliderWrapper = styled.div`
  max-width: ${({theme}) => theme.container.max}px;
  margin: 0 auto;

  ${({theme}) => theme.mq.s} {
    margin-bottom: 20px;
  }

  *:focus {
    outline: none !important;
  }
`;

const config = {
  easing: 'easeExpInOut',
  wrapAround: true,
  autoplay: true,
  autoplayInterval: 3500,
  renderCenterLeftControls: null,
  renderCenterRightControls: null,
  renderBottomCenterControls: null,
  pauseOnHover: false,
  transitionMode: 'fade',
  speed: 1500,
};

const HeroSlider = ({slides}) => (
  <StyledSliderWrapper>
    <Carousel {...config}>
      {slides.map(slide => <HeroSlide key={slide.id} {...slide} />)}
    </Carousel>
  </StyledSliderWrapper>
);

export default HeroSlider;
