import React, {useRef} from 'react';
import styled from 'styled-components';
import {hexToRgba} from 'styles/theme';
import useIntersect from 'helpers/useIntersect';
import {useSpring, animated as a} from 'react-spring';

const StyledAboutWrapper = styled(a.div)`
  margin: 60px 0;
  min-height: 650px;
  display: flex;
  background-color: ${({theme}) => theme.color.grayGreen};
  max-width: ${({theme}) => theme.container.max}px;
  position: relative;
  overflow-x: hidden;
`;

const StyledImage = styled(a.div)`
  flex: 1;
  background: url(${({imageUrl}) => imageUrl}) no-repeat center;
  background-size: cover;
`;

const StyledContent = styled(a.div)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({theme}) => theme.mq.m} {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  section {
    max-width: 340px;
    text-align: center;
    display: grid;
    grid-gap: 3.5rem;
    font-size: ${({theme}) => theme.font.l};
    background-color: ${({theme}) => theme.color.grayGreen};
    padding: 4rem;

    ${({theme}) => theme.mq.m} {
      background-color: ${({theme}) => hexToRgba(theme.color.grayGreen, 0.9)};
    }

    ${({theme}) => theme.mq.s} {
      margin: 2rem 1rem;
      padding: 3rem 2rem;
    }

    h2 {
      text-transform: uppercase;
      font-size: 4.6rem;
      font-weight: 900;
      letter-spacing: 1.4rem;
      color: ${({theme}) => theme.color.bleck};

      ${({theme}) => theme.mq.m} {
        font-size: 3.5rem;
        letter-spacing: .8rem;
      }
    }

    p {
      font-weight: 200;
    }

    blockquote {
      font-style: italic;
      font-weight: 200;

      &:before, &:after {
        content: '"';
      }
    }

    cite {
      text-align: right;
      letter-spacing: 2px;
      margin-right: -3rem;
      margin-top: 1rem;
      font-weight: 600;
      font-size: ${({theme}) => theme.font.s};

      ${({theme}) => theme.mq.m} {
        margin-top: 0;
        margin-right: 0;
      }
    }
  }
`;

const About = ({imageUrl, text}) => {
  const elementRef = useRef(null);
  const [ inView ] = useIntersect(elementRef, {
    threshold: 0,
  });

  const {opacity, transform} = useSpring({
    opacity: inView ? 1 : 0,
    transform: `translateX(${inView ? '0' : '100%'})`,
    config: {mass: 8, tension: 700, friction: 180},
  });

  return (
    <StyledAboutWrapper ref={elementRef} style={{opacity}}>
      <StyledImage imageUrl={imageUrl}/>
      <StyledContent style={{opacity, transform}}>
        <section>
          <h2>About&nbsp;us</h2>
          <p>{text && text.main}</p>
          <blockquote>{text && text.quotation}</blockquote>
          <cite>{text && text.author}</cite>
        </section>
      </StyledContent>
    </StyledAboutWrapper>
  );
};

export default About;
