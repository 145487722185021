import React from 'react';
import styled from 'styled-components';
import Ig from 'assets/icons/ig_color.svg';
import useBodyLock from '../helpers/useBodyLock';

const StyledWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
`;

const StyledOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledClose = styled.button`
  background: none;
  border: none;
  position: absolute;
  height: 40px;
  width: 40px;
  padding: 10px;
  top: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 1003;

  &:focus {
    outline: none;
  }

  &:after, &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 25px;
    background: ${({theme}) => theme.color.white};
    bottom: 50%;
    right: 0;
    transform-origin: center;
  }

  &:after {
    transform: rotate(45deg)
  }

  &:before {
    transform: rotate(-45deg)
  }
`;

const StyledContent = styled.div`
  background: ${({theme}) => theme.color.white};
  display: grid;
  grid-template-columns: auto 335px;
  position: absolute;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;

  ${({theme}) => theme.mq.m} {
    grid-template-columns: auto 260px;
    height: 450px;
  }

  ${({theme}) => theme.mq.s} {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    height: unset;
  }
`;

const StyledDescription = styled.div`
  padding: 20px;
  font-size: 16px;

  ${({theme}) => theme.mq.s} {
    padding: 15px;
  }
`;

const StyledContentHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  align-items: center;

  ${({theme}) => theme.mq.s} {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  a {
    justify-self: right;

    svg {
      width: 30px;
      height: 30px;

      ${({theme}) => theme.mq.s} {
        width: 25px;
        height: 25px;
      }
    }
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    top: 100%;
    background: ${({theme}) => theme.color.grayLight};
  }
`;

const StyledTitle = styled.h5`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const StyledHashtags = styled.p`
  font-size: 1.3rem;
  color: ${({theme}) => theme.color.gray};
`;

const StyledNameWithAvatar = styled.a`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  align-items: center;
  color: ${({theme}) => theme.color.black};
  text-decoration: none;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid thin ${({theme}) => theme.color.grayLight};

    ${({theme}) => theme.mq.s} {
      width: 25px;
      height: 25px;
    }
  }

  h4 {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: -.15px;

    ${({theme}) => theme.mq.s} {
      font-size: 1.3rem;
    }
  }
`;

const StyledImg = styled.img`
  display: inline-block;
  height: 600px;
  
  ${({theme}) => theme.mq.m} {
    height: 450px;
  }
  
  ${({theme}) => theme.mq.s} {
    height: unset;
    width: 85vw;
  }

`;

const IG_URL = 'https://www.instagram.com/onmovemodels_scouts';

const InstagramDetails = ({imageUrl, onClose, alt, description, id, baseData, ...rest}) => {
  useBodyLock();
  console.log(rest);
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledImg alt={alt} src={imageUrl}/>
        <StyledDescription>
          <StyledContentHeader>
            <StyledNameWithAvatar href={IG_URL} target="_blank" rel="noreferrer noopener">
              <img src={baseData.profile}/>
              <h4>{baseData.fullName}</h4>
            </StyledNameWithAvatar>
            <a aria-label="instagram account" href={`https://www.instagram.com/p/${id}`} target="_blank" rel="noreferrer noopener"><Ig/></a>
          </StyledContentHeader>
          <div>
            <StyledTitle>
              {description.text.split(/#(.+)/)[ 0 ]}
            </StyledTitle>
            <StyledHashtags>
              #{description.text.split(/#(.+)/)[ 1 ]}
            </StyledHashtags>
          </div>
        </StyledDescription>
      </StyledContent>
      <StyledClose onClick={onClose}/>
      <StyledOverlay onClick={onClose}/>
    </StyledWrapper>
  );
};

export default InstagramDetails;
