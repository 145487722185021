import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Container from "./containers/Container";
import styled from "styled-components";
import InstagramResize from "./InstagramResize";
import Loader from "./Loader";
import theme from "../styles/theme";


const StyledGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 5px;

  ${({theme}) => theme.mq.m} {
    max-width: 650px;
    margin: 0 auto;
    grid-template-columns: repeat(3, auto);
  }

  ${({theme}) => theme.mq.xs} {
    padding-top: 60px;
  }
`;

const StyledLoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IG_URL = 'https://www.instagram.com/onmovemodels_scouts';
const API_SUFFIX = '?__a=1';
const TYPES = {
  IMAGE: "GraphImage",
  VIDEO: "GraphVideo"
}

const parseResponse = response => {
  const {
    full_name: fullName,
    profile_pic_url: profile,
    followed_by_viewer: followedByViewer,
    edge_owner_to_timeline_media: {edges: timeline},
    username,
  } = response;

  const images = timeline
    .filter(({__typename}) => __typename !== TYPES.VIDEO)
    .slice(0, 6)
    .map(({node}) => ( {
      alt: node.accessibility_caption,
      imageUrl: node.display_url,
      thumbnailUrl: node.thumbnail_resources[ 3 ]?.src || node.display_url,
      dimensions: node.dimensions,
      id: node.shortcode,
      description: node.edge_media_to_caption?.edges[ 0 ]?.node,
      likes: node.edge_liked_by.count,
      type: node.__typename,
    } ))

  return {
    base: {fullName, profile, followedByViewer, username},
    images,
  }
}

const InstagramFeed = () => {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ instagramData, setInstagramData ] = useState(null);

  useEffect(() => {
    ( async () => {
      try {
        const response = await fetch(`${IG_URL}/${API_SUFFIX}`);
        const {graphql} = await response.json();
        const data = parseResponse(graphql.user);
        setInstagramData(data);
        setError(false);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } )();
  }, [])

  if (error) return null;

  return (
    <Container>
      <StyledGrid>
        {
          instagramData
            ? instagramData.images.map(image => <InstagramResize key={image.id} {...image} baseData={instagramData.base} />)
            : <InstagramResize/>
        }
        {loading && (
          <StyledLoaderWrapper>
            <Loader height="50px" fill={theme.color.grayLighter}/>
          </StyledLoaderWrapper>
        )}
      </StyledGrid>
    </Container>
  );
}

InstagramFeed.defaultProps = {};

InstagramFeed.propTypes = {};

export default InstagramFeed;