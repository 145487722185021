import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import InstagramDetails from "./InstagramDetails";
import Heart from 'assets/icons/heart.svg';

const StyledWrapper = styled.div`
  //overflow: hidden;
  position: relative;
  
  span {
    opacity: 0;
    position: absolute;
    display: flex;
    content: '${({ likes }) => likes}';
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 2rem;
    font-weight: 600;
    color: white;
    pointer-events: none;
    transition: transform .1s ease, opacity .3s ease;
    align-items: center;
  }
  
  &:hover {
    span {
      z-index: 1;
      opacity: 1;
      transform: translate3d(-50%, 0%, 0);
    }
  }
`;

const StyledHeart = styled(Heart)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`

const StyledImage = styled.div`
  padding-bottom: 100%;
  background: url("${({ imageUrl }) => imageUrl}") no-repeat center;
  background-size: cover;
  cursor: pointer;
  transition: .3s ease;
  
  &:hover {
    filter: brightness(.6);
  }
`;

const InstagramResize = ({ thumbnailUrl, likes, alt, ...rest }) => {
  const [expanded, setExpanded] = useState(false);

  const open = () => setExpanded(true);
  const close = () => setExpanded(false);

  return (
    <StyledWrapper>
      <span>
        <StyledHeart />
        {likes}
      </span>
      <StyledImage alt={alt} onClick={open} imageUrl={thumbnailUrl} />
      {expanded && <InstagramDetails onClose={close} alt={alt} {...rest} />}
    </StyledWrapper>
  );
}

InstagramResize.defaultProps = {};

InstagramResize.propTypes = {};

export default InstagramResize;