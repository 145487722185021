import React from 'react';
import Carousel from 'nuka-carousel';
import SecondarySlide from './SecondarySlide';
import styled from "styled-components";

const config = {
  wrapAround: true,
  autoplay: true,
  autoplayInterval: 3500,
  renderCenterLeftControls: null,
  renderCenterRightControls: null,
  renderBottomCenterControls: null,
  transitionMode: 'fade',
  pauseOnHover: false,
  speed: 1500,
};

const StyledSecondarySliderWrapper = styled.div`
  *:focus {
    outline: none !important;
  }
`;

const SecondarySlider = ({slides}) => (
  <StyledSecondarySliderWrapper>
    <Carousel {...config}>
      {slides.map(slide => <SecondarySlide key={slide.id} {...slide} />)}
    </Carousel>
  </StyledSecondarySliderWrapper>
);

export default SecondarySlider;
